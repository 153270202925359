export default function Spotify() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      className="w-6 h-6"
      aria-hidden="true"
      fill="currentColor"
    >
      <path d="M9 0c1.242 0 2.414.234 3.516.703a8.832 8.832 0 012.865 1.934 9.3 9.3 0 011.916 2.847A8.885 8.885 0 0118 9a8.797 8.797 0 01-.703 3.48 8.832 8.832 0 01-1.934 2.866 9.185 9.185 0 01-2.883 1.916A8.28 8.28 0 019 18a8.28 8.28 0 01-3.48-.738 9.185 9.185 0 01-2.883-1.916A8.832 8.832 0 01.703 12.48 8.797 8.797 0 010 9c0-1.242.234-2.414.703-3.516a9.146 9.146 0 011.934-2.847A9.036 9.036 0 015.52.703 8.633 8.633 0 019 0zm-.053 10.898c-1.465-.164-3.052-.046-4.763.352a.477.477 0 00-.334.229.518.518 0 00-.053.439c.023.14.1.252.228.334.104.066.218.09.344.073l.096-.02c1.57-.352 3.017-.457 4.342-.317 1.324.14 2.513.54 3.568 1.196.14.07.281.087.422.052a.592.592 0 00.351-.263.528.528 0 00.053-.405.472.472 0 00-.299-.334c-1.172-.726-2.49-1.171-3.955-1.336zm.211-2.9c-1.863-.223-3.615-.088-5.256.404a.576.576 0 00-.369.334.74.74 0 00-.073.411l.02.1a.49.49 0 00.3.403c.175.082.357.1.544.053 1.406-.445 2.947-.568 4.623-.369 1.676.2 3.123.662 4.342 1.389.14.117.305.152.492.105a.78.78 0 00.457-.316.689.689 0 00.07-.528c-.046-.187-.14-.316-.28-.386-1.383-.844-3.007-1.377-4.87-1.6zm.07-3.094a21.718 21.718 0 00-3.146-.017c-1.008.093-1.91.258-2.707.492a.81.81 0 00-.475.404.83.83 0 00-.052.65c.07.188.199.34.386.458a.74.74 0 00.633.07c.68-.211 1.477-.363 2.39-.457a16.95 16.95 0 012.778.017c.961.083 1.899.24 2.813.475a9.645 9.645 0 012.46.984c.212.118.434.153.669.106.234-.047.398-.152.492-.316a.85.85 0 00.088-.65.798.798 0 00-.405-.51 10.987 10.987 0 00-2.777-1.16 16.84 16.84 0 00-3.146-.546z"></path>
    </svg>
  );
}
