import { useState, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const query = `
{
    imageGrid(id: "TreeNCRBrJHgMGAJjW9R5") {
      imagesCollection {
        items {
          title
          url
          width
          height
        }
      }
    }
  }
`;

const backgroundImageQuery = `
{
  photopageImage(id: "1Jv3llQ8KX0TNYc4tNsAbN") {
    image {
      url
    }
  }
}
`;

export default function Photos() {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [images, setImages] = useState(false);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setImages(
          data.imageGrid.imagesCollection.items.map((item) => {
            return {
              ...item,
              src: item.url,
            };
          })
        );
      });
  }, []);

  // Fetch background image
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query: backgroundImageQuery }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        console.log({ data });
        setBackgroundImage(data.photopageImage.image.url);
      });
  }, []);

  if (!images) return null;

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;
  const galleryImages = images.map((image) => {
    return {
      ...image,
      src: image.url + "?q=20",
    };
  });

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div
      className="-mx-2 -mt-4 md:-mt-12 bg-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage}?fit=fill&w=2000)` }}
    >
      <div className="py-6 px-4 max-w-6xl mx-auto">
        <Gallery images={galleryImages} onClick={handleClick} enableImageSelection={false} rowHeight={350} />
        {!!currentImage && (
          <Lightbox
            enableZoom={false}
            mainSrc={currentImage.url}
            mainSrcThumbnail={currentImage.url}
            nextSrc={nextImage.url}
            nextSrcThumbnail={nextImage.url}
            prevSrc={prevImage.url}
            prevSrcThumbnail={prevImage.url}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </div>
  );
}
