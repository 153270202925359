export default function Soundcloud() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 9"
      className="w-6 h-6"
      aria-hidden="true"
      fill="currentColor"
    >
      <path d="M4.698 1.755c.09 0 .145.036.163.108l.007.062.17 4.132-.17 1.98c0 .114-.057.17-.17.17-.045 0-.127-.072-.157-.13l-.013-.04-.113-1.98.113-4.132c0-.114.057-.17.17-.17zm3.17-.68c.094 0 .189.08.217.17l.01.057.113 4.755-.114 1.924-.009.056c-.028.092-.123.17-.217.17-.17 0-.283-.113-.226-.226l-.114-1.924.114-4.755c0-.113.113-.227.226-.227zm-1.642.567c.095 0 .19.078.218.17l.009.056.17 4.189-.114 1.924-.008.056c-.03.092-.124.17-.218.17s-.189-.078-.25-.203L6 7.924 5.887 6 6 1.868c0-.113.113-.226.226-.226zM10.755 0a4.075 4.075 0 014.075 3.736c.283-.113.566-.17.906-.17 1.302 0 2.264.962 2.264 2.264a2.293 2.293 0 01-2.16 2.316l-.16.005H9.282c-.097 0-.194-.125-.22-.232l-.006-.051V.566c0-.146.041-.208.16-.258l.066-.025C9.736.113 10.245 0 10.755 0zM3.17 2.83c.09 0 .145.036.163.08l.007.033.17 3.114-.17 1.98-.043.042c-.042.04-.085.072-.127.072-.09 0-.145-.036-.163-.08L3 8.038l-.17-1.981L3 2.943c.057-.056.113-.113.17-.113zM1.698 3.906c.045 0 .09 0 .107.058l.006.055.17 2.038-.17 1.98a.122.122 0 01-.113.114c-.045 0-.09-.036-.107-.08l-.006-.033-.17-1.981.17-2.038c0-.057.057-.113.113-.113zm-1.472.396l.029.007a.05.05 0 01.02.017l.008.032.226 1.699-.226 1.641s0 .057-.057.057l-.028-.007a.05.05 0 01-.02-.017L.17 7.698 0 6.057l.17-1.699s0-.056.056-.056z"></path>
    </svg>
  );
}
