import { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

const query = `
{
  contactPageCollection {
    items {
      coverImage {
        url
        fileName
        title
      }
      title
      contactText {
        json
      }
    }
  }
}
`;

const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    },
  },
};

export default function Contact() {
  const [contactPageContent, setContactPageContent] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setContactPageContent(data.contactPageCollection.items[0]);
      });
  }, []);

  if (!contactPageContent) return null;

  return (
    <div
      className="-mx-2 -mt-4 md:-mt-12 bg-center bg-cover"
      style={{ backgroundImage: `url(${contactPageContent.coverImage.url}?fit=fill&w=2000)` }}
    >
      <div className="max-w-6xl mx-auto min-h-screen flex items-center justify-center">
        <div className="mx-2 -mt-52 bg-white bg-opacity-20 text-white rounded p-4 backdrop-blur">
          <h1 className="text-4xl font-bold leading-7 sm:truncate sm:text-5xl sm:tracking-tight mt-2 mb-4">
            {contactPageContent.title}
          </h1>
          {documentToReactComponents(contactPageContent.contactText.json, richTextOptions)}
        </div>
      </div>
    </div>
  );
}
