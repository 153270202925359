import { useState, useEffect } from "react";

const query = `
{
    homepageHighlightCollection {
      items {
        name
        image {
          title
          url
        }
        title
        shortText
        ctaText
        ctaLink
      }
    }
  }
`;

export default function HomeHighlight() {
  const [highlight, setHighlight] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setHighlight(data.homepageHighlightCollection.items[0]);
      });
  }, []);

  if (!highlight) return null;

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={highlight.ctaLink}
      style={{ backgroundImage: `url(${highlight.image.url}?fit=fill&w=1200)` }}
      className="bg-center bg-cover block mb-8 min-h-full group hover:cursor-pointer transition-all shadow-lg hover:shadow-2xl"
    >
      <div className="p-8 pt-60 md:pt-80 flex flex-col">
        <h2 className="text-4xl font-bold leading-7 text-white sm:truncate sm:text-5xl sm:tracking-tight sm:leading-snug sm:mb-0 mb-4">
          {highlight.title}
        </h2>
        <p className="mb-4 text-white">{highlight.shortText}</p>
        <button className="border border-white text-white bg-transparent max-w-max px-4 py-2 transition-all group-hover:bg-white group-hover:text-black">
          {highlight.ctaText}
        </button>
      </div>
    </a>
  );
}
