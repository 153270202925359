import { useEffect, useState } from "react";
import HomeHighlight from "../Components/HomeHighlight";

const query = `
{
  homepageBlockList(id: "5CoUqT9JSbrbKJ2UalhFcg") {
    sys {
      id
    }
    listCollection {
      items {
        ... on HomepageBlock {
          title
          url
          backgroundImage {
            title
          	description
          	contentType
          	fileName
          	size
          	url
          	width
          	height
          }
        }
      }
    }
  }
}
`;

const homepageImageQuery = `
{
  homepageImage(id: "6uM6ayZF5vpixMvqW6u2a") {
    image {
      url
    }
  }
}
`;

export default function Home() {
  const [homeBlocks, setHomeBlocks] = useState(null);
  const [homepageImage, setHomepageImage] = useState(null);

  // Fetch homepage blocks
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setHomeBlocks(data.homepageBlockList.listCollection.items);
      });
  }, []);

  // Fetch background image
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/tsk3z5qo3555/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer e-_YNSArgFBpl7-Rlf46ojsxd6wkKyWlJkcnW9VdIVw",
        },
        body: JSON.stringify({ query: homepageImageQuery }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setHomepageImage(data.homepageImage.image.url);
      });
  }, []);

  if (!homeBlocks || !homepageImage) {
    return null;
  }

  return (
    <div
      className="-mx-2 -mt-4 md:-mt-12 bg-center bg-cover"
      style={{ backgroundImage: `url(${homepageImage}?fit=fill&w=2000)` }}
    >
      <div className="max-w-6xl mx-auto pb-16 px-2 pt-8">
        <HomeHighlight />
        {homeBlocks?.length > 0 && (
          <div className="grid gap-4 md:gap-6 grid-cols-2 md:grid-cols-3">
            {homeBlocks.map((block, i) => {
              const { title, url, backgroundImage } = block;

              return (
                <div
                  key={url + i}
                  className="bg-transparent rounded-none shadow-xl hover:shadow-2xl hover:scale-105 hover:rounded-xl overflow-hidden transition-all transform group"
                >
                  <a href={url} key={title} target="_blank" rel="noreferrer" className="relative max-w-full max-h-full">
                    <div
                      style={{ backgroundImage: `url(${backgroundImage.url}?fit=fill&w=600)` }}
                      className="bg-center bg-cover w-full transition-all group-hover:brightness-50 aspect-square"
                    />
                    <p className="absolute w-full text-center top-1/2 right-1/2 translate-x-1/2 translate-y-1/2 text-lg opacity-0 font-semibold text-white group-hover:opacity-100 group-hover:-translate-y-1/2 transform-gpu transition-transform">
                      {title}
                    </p>
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
